.App {
  text-align: center;
  display: flex;
  flex-direction: row;
  margin: 0px;
  padding: 0px;
}

.App-main{
  background-color: #6981b1;
  min-width: 100vh;
  max-height: 100%;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.svgText {
  pointer-events: none;
}

svg text {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.App-options{
  display: flex;
  flex-direction: column;
  background-color: #6981b1;

  width: 100%;
  flex: 30%;

  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(0, 0, 0);
}

.optionelement{
  width: 100%;
}

.App-latest{
  background-color: #6981b1;
/*  max-height: 70vh;
  min-height: 70vh;*/
  flex: 70%;
  width: 100%;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 1vmin);
  color: white;
  text-overflow: clip;
  overflow-y: scroll;
  margin: 0px;
  padding: 0px;
}

.App-latestentry{
  display: flex;
  flex-direction: row;

}
.App-latestsvg{
  width: 30%;
  margin: 0px;
  padding: 0px;
}

.App-latestinfo{
  width:40%;
  margin: 0px;
  padding: 0px;
  font-size: calc(1vmin);

}

.Sidebar{
  background-color: #6c6c70;
  max-height: 100vh;
  max-width: 50vh;
  min-width: 50vh;
/*  min-width: 50vh;*/

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  text-overflow: clip;
}

.App-link {
  color: #61dafb;
}


